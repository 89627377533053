document.addEventListener("DOMContentLoaded", function() {
    const signUpLink = document.getElementById('signUpLink');
    const signInLink = document.getElementById('signInLink');
    const signInTab = document.getElementById('sign-in-tab');
    const signUpTab = document.getElementById('sign-up-tab');

    if (!signUpLink || !signInLink || !signInTab || !signUpTab) {
        return;
    }
    signUpLink.addEventListener('click', function() {
        setTimeout(() => {
            signUpTab.click();
        }, 10); // slight delay to ensure modal is open
    });

    signInLink.addEventListener('click', function() {
        setTimeout(() => {
            signInTab.click();
        }, 10); // slight delay to ensure modal is open
    });
});
